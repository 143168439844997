import { createWebHistory, createRouter } from "vue-router";
import Home from "@/components/HomePage.vue";

const routes = [{
    path: "/",
    name: "Home",
    component: Home,
}, ];

const router = createRouter({
    history: createWebHistory(),
    routes,
    // eslint-disable-next-line
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 };
    }
});

export default router;