<template>
  <div>
    <div class="content-show">
      <div id="fixed-content">
        <div>
          <div
            id="background-1"
            v-bind:style="{
              opacity: 1,
              background:
                'linear-gradient(20deg,rgba(230,179,178,255),rgba(205,173,98,255))',
            }"
          ></div>
        </div>
        <div></div>
        <div
          id="vertical-line-left"
          class="vertical-line vertical-line-left"
        ></div>
        <div
          id="vertical-line-right"
          class="vertical-line vertical-line-right"
        ></div>
        <a
          class="brandmark_small"
          href="https://www.instagram.com/gracespabeautysalon/?hl=en"
          target="_blank"
        >
          <img src="../assets/logo-black.png" />
        </a>
        <div class="languages">
          <img
            src="../assets/english.png"
            title="English"
            @click="changeLanguage('en')"
          />
          <img
            src="../assets/spanish.png"
            title="Español"
            @click="changeLanguage('es')"
          />
        </div>
      </div>
      <div id="scroll">
        <div id="page-content">
          <div id="page-scroll-helper"></div>
          <div id="home-scroll-wrapper">
            <div class="logo-wrapper-wrapper-mobile">
              <div class="logo-wrapper center-container">
                <img src="../assets/logo.png" />
              </div>
            </div>
            <div class="form-wrapper">
              <div class="flip-card">
                <div class="inner">
                  <a
                    class="brandmark"
                    href="https://www.instagram.com/gracespabeautysalon/?hl=en"
                    target="_blank"
                  >
                    <img src="../assets/logo-black.png" />
                  </a>
                  <div class="card-face front-side">
                    <div class="image-holder">
                      <img src="../assets/registration-form-4.jpg" alt="" />
                    </div>
                    <form @submit="submitForm">
                      <h3>{{ this.languageText.TitleForm }}</h3>
                      <div class="form-holder">
                        <input
                          type="text"
                          :placeholder="
                            this.languageText.Name.toUpperCase() + ' *'
                          "
                          v-model="name"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-holder">
                        <input
                          type="number"
                          :placeholder="this.languageText.Birthdate + ' *'"
                          v-model="birthdate"
                          @change="birthDateChanged"
                          class="form-control"
                          required
                        />
                      </div>
                      <div class="form-holder">
                        <select
                          ref="genderSelector"
                          @change="genderSelected"
                          v-model="gender"
                          tabindex="-1"
                          aria-hidden="true"
                          class="form-control"
                          required
                        >
                          <option disabled selected value="">
                            {{ this.languageText.Gender }} *
                          </option>
                          <option>{{ this.languageText.GenderMale }}</option>
                          <option>{{ this.languageText.GenderFemale }}</option>
                          <option>{{ this.languageText.GenderOther }}</option>
                        </select>
                      </div>
                      <div class="form-holder">
                        <input
                          type="text"
                          :placeholder="
                            this.languageText.Email.toUpperCase() + ' *'
                          "
                          v-model="email"
                          class="form-control"
                          @change="mailChanged"
                          required
                        />
                      </div>
                      <div class="form-holder">
                        <input
                          type="text"
                          :placeholder="this.languageText.Phone.toUpperCase()"
                          v-model="phone"
                          class="form-control"
                          @change="phoneChanged"
                        />
                      </div>
                      <div class="error-wrapper">
                        <ul class="error-list">
                          <li
                            class="error-item"
                            v-for="error in errorList"
                            v-bind:key="error"
                            v-bind:error="error"
                          >
                            *{{ error }}
                          </li>
                        </ul>
                      </div>
                      <!-- <div class="checkbox">
                          <label>
                            <input type="checkbox" checked="" /> I agree all statement
                            in
                            <a href="">Terms &amp; Conditions</a>
                            <span class="checkmark"></span>
                          </label>
                        </div> -->
                      <div class="form-submit center-container">
                        <button>{{ this.languageText.Submit }}</button>
                      </div>
                    </form>
                  </div>
                  <div class="card-face back-side">
                    <img src="../assets/logo.png" />
                    <h3>{{ this.languageText.SubmittedHeader }}</h3>
                    <p>
                      {{ this.languageText.SubmittedText }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LanguagesFile from "../language/languages.json";
const minimumAgeYears = 15;
export default {
  name: "HelloWorld",
  data() {
    return {
      minimumAge: 15,
      language: "en",
      previousLanguage: "en",
      url: "https://sheetdb.io/api/v1/64np10i1obdxo",
      isFullyLoaded: false,
      form_sent: false,
      genderSelector: "",
      name: "",
      birthdate: "",
      gender: "",
      email: "",
      phone: "",
      errorList: [],
      lastData: "",
      languageText: {
        TitleForm: "",
        Name: "",
        Birthdate: "",
        Gender: "",
        GenderMale: "",
        GenderFemale: "",
        GenderOther: "",
        Email: "",
        Phone: "",
        Submit: "",
        SubmittedHeader: "",
        SubmittedText: "",
        MailError: "",
        PhoneError: "",
        SubmissionError: "",
        AgeError: "",
        dateGiveAway: "",
      },
    };
  },
  components: {},
  computed: {},
  mounted() {
    this.setLanguageText(this.language);
  },
  methods: {
    genderSelected(event) {
      let value = event.target.value;

      if (value !== "Gender") {
        event.target.classList.add("validInput");
      }
    },
    birthDateChanged(event) {
      var value = event.target.value;

      if (value > this.minimumAge) {
        event.target.classList.remove("invalid-input");
        var index = this.errorList.indexOf(this.languageText.AgeError);
        if (index !== -1) {
          this.errorList.splice(index, 1);
        }
        return;
      }

      //invalid
      event.target.classList.add("invalid-input");
      index = this.errorList.indexOf(this.languageText.AgeError);
      if (index == -1) {
        this.errorList.push(this.languageText.AgeError);
      }
    },
    mailChanged(event) {
      var validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (event.target.value.match(validRegex)) {
        event.target.classList.remove("invalid-input");
        var index = this.errorList.indexOf(this.languageText.MailError);
        if (index !== -1) {
          this.errorList.splice(index, 1);
        }
        return;
      }

      //invalid
      event.target.classList.add("invalid-input");
      index = this.errorList.indexOf(this.languageText.MailError);
      if (index == -1) {
        this.errorList.push(this.languageText.MailError);
      }
    },
    phoneChanged(event) {
      var value = event.target.value.trim();
      /* eslint-disable no-useless-escape */
      var validRegex = /^(\+|00|0|5|6)[0-9 \-\(\)\.]{6,32}$/;
      if (value.match(validRegex) || value.trim().length == 0) {
        event.target.classList.remove("invalid-input");
        var index = this.errorList.indexOf(this.languageText.PhoneError);
        if (index !== -1) {
          this.errorList.splice(index, 1);
        }
        return;
      }

      //invalid
      event.target.classList.add("invalid-input");
      index = this.errorList.indexOf(this.languageText.PhoneError);
      if (index == -1) {
        this.errorList.push(this.languageText.PhoneError);
      }
    },
    reArrangeErrors() {
      var index = this.errorList.indexOf(
        LanguagesFile.MailError[this.previousLanguage]
      );
      if (index !== -1) {
        this.errorList.splice(index, 1);
        this.errorList.push(this.languageText.MailError);
      }
      index = this.errorList.indexOf(
        LanguagesFile.PhoneError[this.previousLanguage]
      );
      if (index !== -1) {
        this.errorList.splice(index, 1);
        this.errorList.push(this.languageText.PhoneError);
      }
      index = this.errorList.indexOf(this.originalAgeErrorText());
      if (index !== -1) {
        this.errorList.splice(index, 1);
        this.errorList.push(this.languageText.AgeError);
      }
    },
    setSubmittedText() {
      this.languageText.SubmittedText = LanguagesFile.SubmittedText[
        this.language
      ].replace("{date}", this.languageText.dateGiveAway);
      this.languageText.SubmittedText = this.languageText.SubmittedText.replace(
        "{email}",
        this.lastData.email
      );
    },
    setAgeErrorText() {
      this.languageText.AgeError = LanguagesFile.AgeError[
        this.language
      ].replace("{minimumAge}", minimumAgeYears);
    },
    originalAgeErrorText() {
      return LanguagesFile.AgeError[this.previousLanguage].replace(
        "{minimumAge}",
        minimumAgeYears
      );
    },
    submitForm(event) {
      event.preventDefault();

      if (this.errorList.length == 0) {
        var data = this.getFormData();

        if (this.form_sent && this.dataIsEqual(data)) {
          this.flipCard();
          return;
        }

        fetch(this.url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: data,
          }),
        })
          .then((response) => {
            response.json();
          })
          .then(() => {
            this.flipCard();
            this.storeData(data);
            this.setSubmittedText();
            this.clearFields();
            this.form_sent = true;
          })
          .catch((error) => {
            console.log("Error when submiting form:", error);
            alert(this.languageText.SubmissionError);
          });
      }
    },
    getFormData() {
      return [
        {
          name: this.name,
          birthdate: this.birthdate,
          gender: this.gender,
          email: this.email,
          phone: this.phone,
        },
      ];
    },
    storeData(data) {
      this.lastData = data[0];
    },
    clearFields() {
      this.name = "";
      this.birthdate = "";
      this.gender = "";
      this.email = "";
      this.phone = "";
    },
    dataIsEqual(data) {
      if (this.lastData !== "") return (data[0].email = this.lastData.email);
      return false;
    },
    flipCard() {
      const card = document.querySelector(".inner");
      const brandmark = document.querySelector(".brandmark");

      card.classList.toggle("is-flipped");
      brandmark.classList.toggle("is-flipped");
    },
    changeLanguage(newLang) {
      if (newLang == this.language) return;
      this.previousLanguage = this.language;
      this.language = newLang;
      this.setLanguageText();
    },
    setLanguageText() {
      this.languageText.TitleForm = LanguagesFile.TitleForm[this.language];
      this.languageText.Name = LanguagesFile.Name[this.language];
      this.languageText.Birthdate = LanguagesFile.Birthdate[this.language];
      this.languageText.Gender = LanguagesFile.Gender[this.language];
      this.languageText.GenderMale = LanguagesFile.GenderMale[this.language];
      this.languageText.GenderFemale =
        LanguagesFile.GenderFemale[this.language];
      this.languageText.GenderOther = LanguagesFile.GenderOther[this.language];
      this.languageText.Email = LanguagesFile.Email[this.language];
      this.languageText.Phone = LanguagesFile.Phone[this.language];
      this.languageText.Submit = LanguagesFile.Submit[this.language];
      this.languageText.SubmittedHeader =
        LanguagesFile.SubmittedHeader[this.language];
      this.setSubmittedText();
      this.setAgeErrorText();
      this.languageText.MailError = LanguagesFile.MailError[this.language];
      this.languageText.PhoneError = LanguagesFile.PhoneError[this.language];
      this.languageText.SubmissionError =
        LanguagesFile.SubmissionError[this.language];
      this.languageText.dateGiveAway = LanguagesFile.dateGiveAway[this.language];
      
      if (this.errorList.length != 0) {
        this.reArrangeErrors();
      }
    },
  },
};
</script>

<style scoped>
</style>

<style src="../css/homeStyle.css"></style>
