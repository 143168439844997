<template>
  <RouterView class="router-view" v-slot="{Component}">
    <Transition name="page-opacity" mode="out-in">
      <component :is="Component"/>
    </Transition>
  </RouterView>
</template>

<script>
export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
#app {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.page-opacity-enter-active,
.page-opacity-leave-active{
  transition: 600ms ease all;
}

.page-opacity-enter-from,
.page-opacity-leave-to{
  opacity: 0;
}

</style>
